import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {getMessage} from '../../components';
import {ConfigActions} from '../../actions';
import {combinePageTitle} from '../../utils';
import {getHelmetMetaTags} from '../../utils/metaTagUtils';

const mapDispatchToProps = dispatch => {
    return {
        setConfig: model => {
            dispatch(ConfigActions.setConfig(model));
        }
    };
};
const mapStateToProps = state => {
    const messages = state
        .get('localization')
        .get('messageProperties')
        .toJS();
    const {locale} = state.get('localization').toJS();
    return {
        messages,
        locale
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class FabLanding extends Component {
    componentDidMount() {
        const {setConfig} = this.props;
        setConfig({
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.marketplace'
            },
            isHomeSuppressed: true
        });
    }

    componentWillUnmount() {
        this.props.setConfig({
            isHomeSuppressed: false
        });
    }

    getHelmetElement() {
        const {locale, messages} = this.props;
        const pageTitle = combinePageTitle(
            messages,
            getMessage,
            'messages.com.epicgames.plugin.store.title.fab_landing'
        );
        const helmetTags = getHelmetMetaTags({
            title: pageTitle,
            description: getMessage(
                messages,
                'messages.com.epicgames.plugin.store.fab.description'
            ),
            image:
                'https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/laxhnvTmRcauhZogwmv3',
            locale
        });

        return <Helmet {...helmetTags} />;
    }

    getHeadingWords() {
        const {locale, messages} = this.props;
        switch (locale) {
            case 'ja':
                return (
                    <>
                        <span className="blue">
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.fab.heading.1'
                            )}
                        </span>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.fab.heading.2')}
                    </>
                );
            case 'ko':
                return (
                    <>
                        <span className="blue">
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.fab.heading.1'
                            )}
                        </span>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.fab.heading.2')}
                    </>
                );
            case 'zh-CN':
                return (
                    <>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.fab.heading.1')}
                        <span className="blue">
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.fab.heading.2'
                            )}
                        </span>
                    </>
                );
            default:
                return (
                    <>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.fab.heading.1')}{' '}
                        <span className="blue">
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.fab.heading.2'
                            )}
                        </span>
                    </>
                );
        }
    }

    render() {
        const {locale, messages} = this.props;

        return (
            <Fragment>
                {this.getHelmetElement()}
                <div className="fab-landing">
                    <div className="spotlight" />
                    <div className="portal left" />
                    <div className="portal right" />
                    <div className="stack">
                        <img
                            className="logo"
                            alt="Fab Marketplace Logo"
                            src="https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/laxhnvTmRcauhZogwmv3"
                        />
                        <h1>{this.getHeadingWords()}</h1>
                        <p>
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.fab.description'
                            )}{' '}
                            <a
                                className="fab read-more"
                                href={`https://www.unrealengine.com/${locale}/blog/fab-epics-new-unified-content-marketplace-launches-today`}>
                                {getMessage(
                                    messages,
                                    'messages.com.epicgames.plugin.store.message.read_more'
                                )}
                            </a>
                        </p>
                        <a className="fab-button" href="https://www.fab.com">
                            <span>
                                {getMessage(
                                    messages,
                                    'messages.com.epicgames.plugin.store.fab.button'
                                )}
                            </span>
                            <i className="fa fa-external-link">&nbsp;</i>
                        </a>
                    </div>
                </div>
            </Fragment>
        );
    }
}

FabLanding.propTypes = {
    setConfig: PropTypes.func,
    messages: PropTypes.object,
    locale: PropTypes.string
};

export default FabLanding;
